<template>
  <div style="padding-top: 20px; padding-bottom: 0px; background: #f4f5f7">

    <div style=" margin: 0 auto; text-align: center; width: 958px; ">

      <div style="display: flex; justify-content: space-between; align-items: center">
        <div style="display: flex; align-items: center">
          <h1  style="cursor:pointer;font-weight: 600; font-size: 40px; text-align: left; color: #2D3640">
            <span @click="$router.push('/blog')" style="cursor:pointer;">Bi'Blog</span>
          </h1>
          <router-link v-if="selected_category_id" :to="'/blog/kategori/'+selected_category_slug" style="color: #5e6b79; font-size: 24px; font-weight: 500; padding-left: 20px; padding-top: 14px;">{{selected_category_name}}</router-link>
          <template v-if="selected_sub_category_slug && allLabels.indexOf(selected_sub_category_slug) > -1">
            <router-link :to="'/blog/kategori/'+selected_category_slug+'/'+selected_sub_category_slug" class="label-link">#{{ selected_sub_category_name }}</router-link>
          </template>

        </div>
      </div>

      <div v-if="selected_category_id" style="display: flex; margin-top: 10px;">
        <router-link :to="'/blog/kategori/'+selected_category_slug+'/freelance'" class="sub-cat-link" :style="selected_sub_category_slug === 'freelance' && ' color:#2D3640; font-weight:500; '">Freelance</router-link>
        <router-link :to="'/blog/kategori/'+selected_category_slug+'/genel'" class="sub-cat-link" :style="selected_sub_category_slug === 'genel' && ' color:#2D3640; font-weight:500; '">Genel</router-link>
        <router-link :to="'/blog/kategori/'+selected_category_slug+'/girisimci'" class="sub-cat-link" :style="selected_sub_category_slug === 'girisimci' && ' color:#2D3640; font-weight:500; '">Girişimci</router-link>
        <router-link :to="'/blog/kategori/'+selected_category_slug+'/is-yonetim'" class="sub-cat-link" :style="selected_sub_category_slug === 'is-yonetim' && ' color:#2D3640; font-weight:500; '">İş & Yönetim</router-link>
        <router-link :to="'/blog/kategori/'+selected_category_slug+'/pazarlama'" class="sub-cat-link" :style="selected_sub_category_slug === 'pazarlama' && ' color:#2D3640; font-weight:500; '">Pazarlama</router-link>
        <router-link :to="'/blog/kategori/'+selected_category_slug+'/ses-muzik'" class="sub-cat-link" :style="selected_sub_category_slug === 'ses-muzik' && ' color:#2D3640; font-weight:500; '">Ses & Müzik</router-link>
        <router-link :to="'/blog/kategori/'+selected_category_slug+'/tasarim'" class="sub-cat-link" :style="selected_sub_category_slug === 'tasarim' && ' color:#2D3640; font-weight:500; '">Tasarım</router-link>
        <router-link :to="'/blog/kategori/'+selected_category_slug+'/video-animasyon'" class="sub-cat-link" :style="selected_sub_category_slug === 'video-animasyon' && ' color:#2D3640; font-weight:500; '">Video & Animasyon</router-link>
        <router-link :to="'/blog/kategori/'+selected_category_slug+'/yazi-ceviri'" class="sub-cat-link" :style="selected_sub_category_slug === 'yazi-ceviri' && ' color:#2D3640; font-weight:500; '">Yazı & Çeviri</router-link>
      </div>
      <div v-else style="display: flex; margin-top: 10px;">
        <router-link to="/blog/kategori/ilham-al" class="sub-cat-link main">İlham Al</router-link>
        <router-link to="/blog/kategori/ogren" class="sub-cat-link main">Öğren</router-link>
      </div>



      <div v-if="!pageLoaded" v-for="x in 2" style="padding-bottom: 20px;">


        <div class="blog-loader-inner">
          <div class="blog-loader-1 blog-loader">
            <div class="blog-header-loader blog-loader"></div>
          </div>
          <div class="blog-loader-2 blog-loader">
            <div class="blog-small-header-loader blog-loader"></div>
            <div style="display: flex; margin-top: 15px; align-items: center" v-for="x in 4">
              <div class="blog-avatar-loader blog-loader"></div>
              <div>
                <div class="blog-title-loader blog-loader" style="width: 140px;"></div>
                <div class="blog-title-loader blog-loader" style="width: 160px; height: 15px;"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-loader-inner">
          <div class="blog-loader-2 blog-loader">
            <div class="blog-small-header-loader blog-loader" style="margin-top: auto;"></div>
            <div class="blog-title-loader blog-loader"></div>
          </div>
          <div class="blog-loader-1 blog-loader">
            <div class="blog-header-loader blog-loader" style="margin-top: auto;"></div>
            <div class="blog-title-loader blog-loader" style="width: 400px"></div>
          </div>
        </div>
      </div>



      <div v-if="pageLoaded" >

        <div style="margin-top: 40px; display: flex; flex-wrap: wrap;width: 958px; justify-content: space-between">
          <a
            style="margin-bottom: 60px;"
            v-for="block in blogLists"
            :href="'/blog/'+block.slug"

            @contextmenu="setWhereFrom"
            @click.stop.prevent="preventClicking($event)"
            class="nounderlinehover clickDiv1"

            >
            <div @click="openBlogDetailModal(block)">
              <div v-if="block.style_type !== 'list'" :class="['card'+block.size_type, 'generalCard', 'card_'+block.style_type, block.color]"
                   :style="'background-size: cover; background-image: url('+block.image_url_list+');'">
                <div v-if="block.style_type === 'bottom_title' || block.style_type === 'long_title'" class="bottom_text_placeholder">
                  <p class="subheader">{{block.subheader}}</p>
                  <p class="title" v-html="block.header" :style="'backgroundColor:'+computedBG(block.header_bg_color)"></p>
                  <p class="subheader2">{{block.subheader2}}</p>
                </div>
                <div v-else>
                  <p class="subheader">{{block.subheader}}</p>
                  <p class="title" v-html="block.header" ></p>
                  <p class="subheader2">{{block.subheader2}}</p>
                  <p style="margin-left: 20px!important; margin-bottom: 120px;"  v-if="block.button_text" :class="block.color === 'black' ? 'red-button' : 'white-button'" >{{block.button_text}}</p>

                </div>
              </div>
            </div>
          </a>
        </div>



      </div>



      <div style="display: flex" v-if="showSeemore">
        <div v-if="nextPage !== 2" class="mainpage-learnmore-div">
          <router-link
            @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'Blog Home')"
            @click.native="handleGlobalClick($store.state.previousComponentPage,'Blog Home')"
            :to="prevPageURL" v-if="v3DisplaySeeMore">
          <span style="text-decoration: none" >
            Önceki Sayfa
          </span>
          </router-link>
        </div>

        <div class="mainpage-learnmore-div" v-if="showSeemore && blogLists.length > 19">

          <router-link
            @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'Blog Home')"
            @click.native="handleGlobalClick($store.state.previousComponentPage,'Blog Home')"
            :to="nextPageURL" v-if="v3DisplaySeeMore">
          <span style="text-decoration: none" >
            Sonraki Sayfa
          </span>
          </router-link>
        </div>
      </div>


      <div class="hiddendiv"></div>
      <div class="hiddendiv"></div>
      <div class="hiddendiv"></div>


    </div>
  </div>
</template>

<script>
  import FooterNav from "../../../../components/shared/navFooter";


  export default {
    name: "src-pages-body-staticContents-blog-list-v2",
    components: {FooterNav},
    data() {
      return {

        v3Offset: 0,
        v3DisplaySeeMore: true,
        v3seeMoreLoading: false,
        pageLoaded: false,
        blogLists: [
          {
            title:null,
            subtitle:null,
            data: [
              {
                "header": "",
                "subheader": "",
                "subheader2": null,
                "style_type": "",
                "size_type": "A2",
                "slug": "",
                "color": "",
                "renderedLists": [],
                "image_url_list": "https://gcdn.bionluk.com/site/general/blank_gig.png",
                "description": null,
                "footer_div":{},
                "htmlList":[]
              }
            ]
          }
        ],

        selected_category_id: null,
        selected_category_name: null,
        selected_category_slug: null,
        selected_sub_category_id: null,
        selected_sub_category_name: null,
        selected_sub_category_slug: null,

        allLabels:['bifacts','guncel','ipucu','kaynak','liste','nasil-olunur','nedir','ozel-gun'],
      };
    },


    methods: {
      computedBG(hex){
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ?
          'rgba('+ parseInt(result[1], 16) + ','+ parseInt(result[2], 16) + ',' +  parseInt(result[3], 16) + ',0.5)'
          : null;
      },
      preventClicking(event) {

        event.returnValue = false;
        return false;
      },
      setWhereFrom(){
        window.localStorage.setItem("whereFrom",  this.$store.state.componentMap.page);
        window.localStorage.setItem("clickedFrom", 'Guest Home');
        this.$store.state.clickedFrom = 'Guest Home';
      },
      getBlogListv4(isCategory, categorySlug = '', subCategorySlug = '') {
        this.blogLists = [];
        this.v3seeMoreLoading = true;
        this.api.content.getBlogListv4(this.$route.query.page ? (this.$route.query.page - 1) : 0, 20, categorySlug, subCategorySlug)
          .then(({data}) => {
            let result = data;
            this.pageLoaded = true;
            this.v3seeMoreLoading = false;
            if (result.success) {
              if(result.data.redirect_url){
                this.sendPrerender302(result.data.redirect_url,"301");
              } else {

                if(! result.data.blog_list.length){
                  this.v3DisplaySeeMore = false;
                } else {
                  this.v3DisplaySeeMore = true;
                }
                if(this.blogLists.length === 1){
                  this.blogLists = result.data.blog_list;
                } else {
                  this.blogLists = [...this.blogLists, ...result.data.blog_list];
                }
                this.selected_category_id = result.data.selected_category_id;
                this.selected_category_name = result.data.selected_category_name;
                this.selected_category_slug = result.data.selected_category_slug;
                this.selected_sub_category_id = result.data.selected_sub_category_id;
                this.selected_sub_category_name = result.data.selected_sub_category_name;
                this.selected_sub_category_slug = result.data.selected_sub_category_slug;
                this.v3Offset = this.v3Offset + 1;


                if(this.selected_category_name){
                  this.$store.state.indexHTMLTitle = `${this.metaTitle}  | Bionluk Blog`;
                }

              }
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: err});
          });
      },

      openBlogDetailModal(block) {
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.BLOG_DETAIL, info: {block}});
      },
    },
    computed: {
      metaTitle(){
        if(this.selected_sub_category_name){
          return this.selected_category_name + ' - ' + this.selected_sub_category_name
        } else {
          return this.selected_category_name;
        }
      },
      showSeemore(){
        if(this.blogLists.length > 19){
          return true
        } else {
          if(this.$route.query.page > 1){
            return true
          } else {
            return false;
          }
        }
      },
      prevPageURL(){
        return'?page='+ (this.nextPage -2)
      },
      nextPageURL(){
        return '?page='+ this.nextPage;
      },
      nextPage(){
       return this.$route.query.page && this.$route.query.page > 0 ? ((parseInt(this.$route.query.page) + 1)) : 2;
      },
      actualPath() {
        let fullPath = this.route.fullPath;
        fullPath = fullPath.split('/');
        let params = [];
        fullPath.forEach(function (element) {
          if (element.length && element !== "blog" && element !== "category") {
            params.push(element);
          }
        });
        return params;
      }
    },
    watch:{
      "$store.state.routerParams": function (newValue, oldValue) {
        if(newValue !== oldValue){
          this.v3Offset = this.$route.query.page ? ((this.$route.query.page-1)) : 0;
          this.pageLoaded = false;
          this.getBlogListv4(this.actualPath[0], this.actualPath[1], this.actualPath[2]);
        }
      },
    },
    mounted() {
      this.v3Offset = this.$route.query.page ? ((this.$route.query.page-1)) : 0;
      this.pageLoaded = false;
      this.getBlogListv4(this.actualPath[0], this.actualPath[1], this.actualPath[2]);
    }

  }
</script>

<style scoped lang="scss">

.label-link{
  color: #42b983;
  font-weight: 600;
  font-size: 16px;
  padding-left: 10px;
  padding-top: 14px;
  font-style: italic;
}

.sub-cat-link{
  padding-right: 15px;
  font-size: 16px; color: #5e6b79; font-weight: 400;
  &.main{
    padding-right: 25px;
    font-size: 20px;
  }
  &:hover{
    color: #2D3640;
    text-decoration: none;
  }
}

  .red-button {
    position: absolute;
    bottom: 40px;
    width: 190px;
    height: 50px;
    padding-bottom: 3px;
    border-radius: 2px;
    background-color: #fd4056;
    border-color: #fd4056;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    margin-left: 40px;
    color: #ffffff;

  &:hover {
     background-color: #fd4056 !important;
   }
  }

  .white-button{
    position: absolute;
    bottom: 60px;
    width: 190px;
    height: 50px;
    padding-bottom: 3px;
    border-radius: 2px;
    background-color: transparent;
    border-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    margin-left: 40px;
    color: #ffffff;
  &:hover{
     background-color: transparent!important;
   }
  }


  .generalCard {
    text-align: left;
    position: relative;
    cursor: pointer;
    border-radius: 16px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 15px 20px 0 rgba(40, 60, 98, 0.1);
  }

  .generalCard:hover {

    box-shadow: 0 15px 20px 0 rgba(40, 60, 98, 0.2);
    text-decoration: none !important;
  }

  .cardA1 {

    width: 459px;
    height: 355px;
    background-position: center;
    background-size: 459px 355px;
  }

  .cardA2 {
    width: 289px;
    height: 355px;


  }

  .cardB1 {
    width: 289px;
    height: 355px;
    background-position: center;
    background-size: 289px 355px;


  }

  .cardB2 {
    width: 459px;
    height: 355px;
    background-position: center;
    background-size: 459px 355px;



  }

  .freelancer-list {
    margin-left: 18px;
    margin-right: 18px
  }

  .freelancer-list-div {
    display: flex;
    height: 66px;
  }

  .freelancer-list-div-div {
    align-self: center;
  }

  .freelancer-list-avatar {
    height: 48px;
    border: 1px solid #dde3eb;
    border-radius: 10px;
  }

  .freelancer-list-second-flex {
    margin-left: 15px;
    height: 48px;
    align-self: center
  }

  .freelancer-list-username {
    line-height: 20px;
    font-size: 15px;
    font-weight: 400;
    color: rgb(36, 36, 36);
    opacity: 0.6;
  }

  .freelancer-list-username_s {
    line-height: 23px;
    font-size: 17px;
    font-weight: 500;
    color: rgb(36, 36, 36);
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 150px;
    max-height: 80px;
    white-space: pre;
  }

  .freelancer-list-user-title {
    line-height: 16px;
    font-size: 13px;
    font-style: italic;
    color: rgb(139, 149, 161);
  }

  .freelancer-list-border {
    margin-left: 100px;
    border-bottom: 1px solid #e1e1e1;
  }

  .freelancer-list-border_s {
    margin-left: 60px;
    border-bottom: 1px solid #e1e1e1;
  }

  .card_bold .title {

    font-size: 32px;
    bottom: 56px;
    left: 30px;
    position: absolute;
    z-index: 1;
    font-weight: 800;
    shadow: 0 15px 20px 0 rgba(40, 60, 98, 0.1);
    width: 100%;
    word-break: break-all;
    text-wrap: normal;

  }

  .card_bold .subheader {

    font-weight: 400;
    opacity: 0.6;
    font-size: 17px;
    line-height: 1.56;
    position: absolute;
    bottom: 32px;
    left: 30px;

  }

  .card_up_title .title {

    font-size: 23px;
    font-weight: 600;
    padding-left: 20px;

    letter-spacing: 0.34px;
    text-align: left;
    line-height: 30px;

  }




  .card_up_title .subheader {

    opacity: 0.7;
    font-size: 15px;
    letter-spacing: -0.24px;



    padding: 18px 20px 6px 20px;

    font-size: 15px;
    opacity: 0.75;
    font-weight: 500;
    line-height: 18px;

  }

  .card_up_title .subheader2 {

    position: absolute;
    bottom: 20px;
    left: 20px;
    font-size: 14px;
    opacity: 0.7;

    letter-spacing: -0.32px;
    line-height: 19px;

  }

  .card_list .title {

    font-size: 24px;
    font-weight: 600;
    padding-left: 20px;

    letter-spacing: 0.34px;
    text-align: left;
    line-height: 30px;

  }

  .card_list .subheader {

    opacity: 0.7;
    font-size: 15px;
    letter-spacing: -0.24px;



    padding: 18px 20px 6px 20px;

    font-size: 15px;
    opacity: 0.75;
    font-weight: 500;
    line-height: 18px;

  }

  .card_list .subheader2 {

    position: absolute;
    bottom: 20px;
    left: 20px;
    font-size: 14px;
    opacity: 0.7;

    letter-spacing: -0.32px;
    line-height: 19px;

  }

  .bottom_text_placeholder {

    position: absolute;
    bottom: 16px;
    left: 12px;
    right: 12px;
  }

  .card_bottom_title .subheader {

    z-index: 1;

    font-size: 16px;
    opacity: 0.75;
    font-weight: 500;
    line-height: 19px;

  }

  .card_bottom_title .title {

    font-size: 23px;
    font-weight: 600;
    line-height: 29px;

  }


  .card_long_title .title {
    padding: 6px 10px 8px;
    border-radius: 8px;

    bottom:4px;

    text-align: left;
    position: absolute;

    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;

  }





  .card_bottom_title .subheader2 {

    padding-top: 16px;
    font-size: 15px;
    z-index: 1;
    letter-spacing: -0.32px;
    line-height: 21px;
    opacity: 0.6;

  }

  .white {
    color: #fff;
  }

  .black {
    color: #242424;
  }

  .card_date_line {
    display: flex;
    width: 100%;
    height: 20px;
    align-content: baseline;
    align-items: baseline;
    align-self: baseline
  }

  .card_date_line_left {
    padding-left: 10px;
    flex: 1;
    color: #2d3640;
    font-size: 32px;
    font-weight: 600;
    text-align: left
  }

  .card_date_line_right {
    flex: 1;
    font-size: 18px;
    font-weight: 500;
    text-align: right;
    color: #8b95a1;
    padding-right: 12px;
  }

  .divA2 {
    margin-left: 40px;
  }

  .divB2 {
    margin-left: 40px;
  }


  /*loader parts*/

  @keyframes loading {
    10% {
      opacity: .4;
    }
    2% {
      opacity: .1;
    }
  }

  .blog-loader {
    animation-name: loading;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
  }

  .blog-loader-inner {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    width: 788px;
  }

  .blog-loader-1 {
    display: flex;
    flex-direction: column;
    width: 419px;
    height: 315px;
    background-color: #e1e6ed;
    border-radius: 16px;
    padding: 20px;
  }

  .blog-loader-2 {
    display: flex;
    flex-direction: column;
    width: 249px;
    height: 315px;
    background-color: #e1e6ed;
    border-radius: 16px;
    padding: 20px;
  }

  .blog-header-loader {
    width: 300px;
    height: 25px;
    background-color: #bdc5d0;
  }

  .blog-small-header-loader {
    width: 200px;
    height: 25px;
    background-color: #bdc5d0;
  }

  .blog-title-loader {
    width: 220px;
    height: 20px;
    background-color: #bdc5d0;
    margin-top: 10px;
  }

  .blog-avatar-loader {
    width: 50px;
    height: 50px;
    background-color: #bdc5d0;
    margin-right: 10px;
    border-radius: 10px;
  }

  .clickDiv1{

  }


  .mainpage-learnmore-div {



    font-size: 14px;
    width: 120px;
    height: 40px;
    border-radius: 2px;


    border: 1px solid rgba(75, 79, 82, .2);
    cursor: pointer;

    margin: 60px auto 0;
  }

  .mainpage-learnmore-div:hover {
    width: 120px;
    height: 40px;
    border-radius: 2px;
    border: solid 1px rgba(75, 79, 82, 0.5);
    margin: 60px auto 0;

  }

  .mainpage-learnmore-div span {

    font-weight: 500;
    color: rgb(45, 54, 64);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    text-decoration: none;
  }
</style>
